import { Reducer, useEffect, useReducer } from "react";
import { v4 as uuidv4 } from "uuid";
import { useBuyingPharmacy } from "../../../contexts/BuyingPharmacyContext";
import {
  DrugInfo,
  RequestState,
  SuggestedNDC,
  NDCTaggedItemList,
} from "../../../utilities/types";
import { useSearchParams } from "../../../utilities/url";
import { shouldSkipNotFoundSentryNotification } from "../../../services/request/shouldSkipNotFoundSentryNotification";
import { getQuotes } from "../../../services/quotes";
import { getSearch } from "../../../services/searches";
import { webFormatter } from "./useCompare.webFormatter";
import { enhancedFormatter } from "./useCompare.enhancedFormatter";

export enum QuoteErrorDetailEnum {
  NOT_FOUND = "No quotes found",
  GENERIC_ERROR = "An error occurred while fetching quotes",
}

export type QuoteState = {
  traceId: string;
  suggestedNDC: SuggestedNDC | null;
  itemListsByNDC: NDCTaggedItemList[];
  quoteRequestState: RequestState;
  relatedDrugsByNDC: Record<string, DrugInfo>;
  errorDetail?: QuoteErrorDetailEnum;
  suggestedDrug?: DrugInfo;
};

const NOT_FOUND_MESSAGE = "No quotes found";

export function useCompare(
  enhancedConfig: {
    enhancedClientVersion: string;
    onStartSearch: () => void;
  } | void
) {
  const { onStartSearch, enhancedClientVersion } = enhancedConfig ?? {};
  const {
    ndc = "",
    from,
    unit_size: unitSize,
    drug_name: drugName,
    generic_product_identifier: genericProductIdentifier,
  } = useSearchParams(
    "ndc",
    "from",
    "unit_size",
    "drug_name",
    "generic_product_identifier"
  );

  const { currentBuyingPharmacyId: pharmacyId } = useBuyingPharmacy();

  const [quoteState, setQuoteState] = useReducer<
    Reducer<QuoteState, Partial<QuoteState>>
  >((state, newState) => ({ ...state, ...newState }), {
    suggestedNDC: null,
    itemListsByNDC: [],
    quoteRequestState: RequestState.PENDING,
    errorDetail: undefined,
    relatedDrugsByNDC: {},
    suggestedDrug: undefined,
    traceId: "",
  });

  useEffect(() => {
    init();

    async function init() {
      if (!ndc || pharmacyId === null) return;

      setQuoteState({
        quoteRequestState: RequestState.PENDING,
        traceId: uuidv4(),
      });

      onStartSearch?.();

      try {
        // check for UPCs
        const searchResponse = await getSearch({
          text: ndc,
          pharmacyId,
        });
        const { searchResults } = searchResponse?.data.data ?? {};
        const mainNdc =
          searchResults?.length === 1
            ? searchResults[0].representativeDrugInfo.ndc
            : ndc;

        const response = await getQuotes({
          ndc: mainNdc,
          drugName,
          unitSize,
          pharmacyId,
          genericProductIdentifier,
          shouldSkipSentryNotification: shouldSkipNotFoundSentryNotification,
        });

        const quotes = response?.data?.data;
        if (!quotes) throw new Error(QuoteErrorDetailEnum.NOT_FOUND);

        const newQuoteState = enhancedClientVersion
          ? enhancedFormatter({ ndc: mainNdc, quotes, enhancedClientVersion })
          : webFormatter({ ndc: mainNdc, quotes });

        setQuoteState(newQuoteState);
      } catch (error: any) {
        const responseStatus = error?.response?.status;
        let errorDetail = QuoteErrorDetailEnum.GENERIC_ERROR;
        if (error.message === NOT_FOUND_MESSAGE || responseStatus === 404) {
          errorDetail = QuoteErrorDetailEnum.NOT_FOUND;
        }
        setQuoteState({ quoteRequestState: RequestState.ERROR, errorDetail });
      }
    }
  }, [
    ndc,
    drugName,
    unitSize,
    pharmacyId,
    enhancedClientVersion,
    genericProductIdentifier,
    onStartSearch,
  ]);

  return { ndc, from, drugName, quoteState };
}
