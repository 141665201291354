import React, { useMemo } from "react";
import "twin.macro";
import {
  getAdminPharmacy_legacy,
  updateAdminPharmacy,
} from "../../../../../services/pharmacies";
import { AdminPharmacyForm } from "./AdminPharmacyForm/AdminPharmacyForm";
import { useEditForm_legacy } from "../../../../../components/antd/form/useEditForm";
import { WideContainer } from "../../../../../components/containers/WideContainer";

export function EditAdminPharmacy() {
  const { values, onSubmit } = useEditForm_legacy({
    name: "pharmacy",
    update: updateAdminPharmacy,
    fetchValues: getAdminPharmacy_legacy,
  });

  const { id, initialValues, changeLogNotes } = useMemo(() => {
    if (!values) return {};
    const { id, changeLogNotes, ...initialValues } = values;
    return { initialValues, id, changeLogNotes };
  }, [values]);

  return (
    <WideContainer tw="pb-[200px]">
      <AdminPharmacyForm
        isUpdateForm
        id={id}
        initialValues={initialValues}
        changeLogNotes={changeLogNotes}
        onSubmit={onSubmit}
      />
    </WideContainer>
  );
}
