import { useEffect, useState } from "react";
import { getAdminPharmacies, getAdminPharmacyOptions } from "../../../../../../services/pharmacies";
import { SelectOption } from "../../../../../../components/rxLibrary/selects/select";
import { displayErrorModal } from "../../../../../../utilities/errors/displayErrorModal";

type ExistingPharmacy = {
  id: number,
  name: string
}

export function useAdminPharmacyOptions() {
  const [adminPharmacyOptions, setAdminPharmacyOptions] = useState<{
    pmsOptions: SelectOption[];
    stateOptions: SelectOption[];
    supplierOptions: SelectOption[];
    timezoneOptions: SelectOption[];
    supplierScopeOptions: SelectOption[];
    pharmacyOrganizationOptions: SelectOption[];
    existingPharmacies: ExistingPharmacy[];
  }>();

  useEffect(() => {
    init();

    async function init() {
      try {
        const response = await getAdminPharmacyOptions();
        const pharmacyOptions = response?.data?.data?.pharmacyOptions;
        if (!pharmacyOptions) {
          throw new Error("Error fetching no admin pharmacy options");
        }

        // TODO - move pharmacies to the BE
        const pharmaciesResponse = await getAdminPharmacies();
        const pharmacies = pharmaciesResponse?.data?.data?.pharmacies ?? [];
        const existingPharmacies = pharmacies.map(({ id, name }) => ({ id, name }));

        const {
          pms,
          state,
          supplier,
          timezone,
          supplierScope,
          pharmacyOrganization,
        } = pharmacyOptions;

        const stateOptions = state.map((v) => ({
          value: v.code,
          label: `${v.name} - ${v.code}`,
        }));
        const supplierOptions = supplier.map((v) => ({
          value: v.id,
          label: v.name,
        }));
        const pmsOptions = pms.map((v) => ({ value: v, label: v }));
        const timezoneOptions = timezone.map((v) => ({ value: v, label: v }));
        const supplierScopeOptions = supplierScope.map((v) => ({
          value: v,
          label: v,
        }));
        const pharmacyOrganizationOptions = pharmacyOrganization.map((v) => ({
          value: v.id,
          label: v.name,
        }));

        setAdminPharmacyOptions({
          pmsOptions,
          stateOptions,
          supplierOptions,
          timezoneOptions,
          supplierScopeOptions,
          pharmacyOrganizationOptions,
          existingPharmacies
        });
      } catch (error) {
        console.error("Error formatting admin pharmacy options", error);
        displayErrorModal({ title: "Error getting page data" });
      }
    }
  }, []);

  return adminPharmacyOptions;
}
