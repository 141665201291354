import React, { useContext, useMemo } from "react";
import ManufacturersContext from "../../../contexts/ManufacturersContext";
import { Button } from "../../../components/rxLibrary/buttons";
import { IconNote } from "../../../components/rxLibrary/icons";
import { useManufacturerReport } from "../useManufacturerReport/useManufacturerReport";
import { ManufacturerReportType } from "../Manufacturer.constants";
import { ManufacturerReportModal } from "./ManufacturerReportModal";
import { ManufacturerReportGrid } from "./ManufacturerReportGrid";
import { ManufacturerReportWrapper } from "./ManufacturerReportWrapper";
import { ManufacturerReportError } from "./ManufacturerReportError";
import { ManufacturerReportChart } from "./ManufacturerReportChart";

export function ManufacturerReports() {
  const { manufacturer } = useContext(ManufacturersContext);
  const { report, reportDates, shouldRenderReport, renderReport } =
    useManufacturerReport();
  const { reportId, reportType, iframeProps } = report;

  const [startDate, endDate] = useMemo(() => {
    return reportDates.map((date) => date.format("YYYY-MM-DD"));
  }, [reportDates]);

  if (!manufacturer) {
    return <ManufacturerReportError />;
  }

  if (reportType === ManufacturerReportType.COMING_SOON) {
    return <ManufacturerReportWrapper>Coming Soon</ManufacturerReportWrapper>;
  }

  if (!shouldRenderReport) {
    return (
      <div tw="flex items-center justify-center h-full">
        <Button icon={<IconNote />} onClick={renderReport}>
          Get Report
        </Button>
      </div>
    );
  }

  if (reportType === ManufacturerReportType.MODE) {
    return (
      <ManufacturerReportModal
        endDate={endDate}
        reportId={reportId}
        startDate={startDate}
        iframeProps={iframeProps}
        manufacturerId={manufacturer.id}
        key={reportId}
      />
    );
  }

  if (
    reportType === ManufacturerReportType.AG_GRID &&
    "Report" in report &&
    report.Report
  ) {
    return (
      <ManufacturerReportGrid
        reportId={reportId}
        startDate={startDate}
        endDate={endDate}
        Report={report.Report}
        manufacturerId={manufacturer.id}
        key={reportId}
      />
    );
  }

  if (
    reportType === ManufacturerReportType.AG_CHART &&
    "Report" in report &&
    report.Report
  ) {
    return (
      <ManufacturerReportChart
        reportId={reportId}
        startDate={startDate}
        endDate={endDate}
        Report={report.Report}
        manufacturerId={manufacturer.id}
        key={reportId}
      />
    );
  }

  return <ManufacturerReportError />;
}
