import { useContext, useEffect, useMemo } from "react";
import "twin.macro";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import { useBuyingPharmacy } from "./contexts/BuyingPharmacyContext";
import { ShoppingCartServerContextProvider } from "./contexts/shoppingContexts/ShoppingCartServerContext/ShoppingCartServerContext";
import { ShoppingContextProvider } from "./contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import { StorageContextProvider } from "./contexts/shoppingContexts/StorageContext";
import { ShoppingNotificationsContextProvider } from "./contexts/shoppingContexts/shoppingNotifications";
import { ClientPageAlertProvider } from "./contexts/ClientPageAlertContext";
import { NavBar } from "./components/NavBar/NavBar";
import { RoleProtectedRoute } from "./components/RoleProtectedRoute";
import { Compare } from "./pages/Compare/Compare";
import { PharmacyLoader } from "./components/PharmacyLoader";
import { PageAlertsNotifications } from "./components/pageAlerts/PageAlertsNotifications";
import EnhancedSupplierNavBar from "./enhanced/EnhancedSupplierNavBar";
import EnhancedSupplierBrowserBar from "./enhanced/EnhancedSupplierBrowserBar";
import { EnhancedSupplierAccount } from "./enhanced/EnhancedSupplierAccount/EnhancedSupplierAccount";
import { Performance } from "./pages/Performance/Performance";
import { UnauthorizedPage } from "./pages/UnauthorizedPage";
import { DownloadClientApp } from "./pages/DownloadClientApp";
import { UserBusinessRoles } from "./utilities/types";
import { X_CLIENT_SESSION_ID } from "./utilities/config";
import AppContext from "./contexts/AppContext";
import { EnhancedScreenLockInactivity } from "./enhanced/EnhancedScreenLockInactivity";
import EnhancedClientConfigContext from "./enhanced/EnhancedClientConfigContext";
import { ReloadPageAlert } from "./components/pageAlerts/ReloadPageAlert";
import { Manufacturer } from "./pages/Manufacturer/Manufacturer";
import { Admin } from "./pages/Admin/Admin";
import { SelectDrug } from "./pages/SelectDrug";
import { DemoMode } from "./pages/DemoMode";
import { ManufacturerStore } from "./pages/ManufacturerStore/ManufacturerStore";
import EnhancedSupplierSafetyScreen from "./enhanced/app_widgets/EnhancedSupplierSafetyScreen";
import { ChatButton } from "./components/ChatButton/ChatButton";
import { PageAlertWindowWidth } from "./components/pageAlerts/PageAlert_WindowWidth";
import { PharmacyHome } from "./pages/PharmacyHome/PharmacyHome";
import { InvoiceHistory } from "./pages/InvoiceHistory/InvoiceHistory";
import { Home } from "./pages/Home";
import { RefillOpportunitiesPage } from "./pages/RefillOpportunitiesPage";
import { Shopping } from "./pages/shopping/Shopping";

export function App() {
  console.log("Rendering App");

  return (
    <div tw="min-h-screen print:h-unmodified">
      <Router>
        <ReloadPageAlert />
        <PageAlertWindowWidth />
        <PageAlertsNotifications />

        <ClientPageAlertProvider>
          <Switch>
            <Route exact path="/" component={Home} />

            <Route exact path="/supplierBrowserBar">
              <EnhancedSupplierBrowserBar />
            </Route>

            <Route exact path="/supplierSafetyScreen">
              <EnhancedSupplierSafetyScreen />
            </Route>

            <Route exact path="/demo" component={DemoMode} />

            <Route exact path="/invoice-history" component={InvoiceHistory} />

            <RoleProtectedRoute
              path="/manufacturer-store/:supplierId"
              role={UserBusinessRoles.BUYING}
              component={ManufacturerStore}
            />

            <RoleProtectedRoute
              path="/(search|selectDrug|compare|supplierAccounts|refill-opportunities)"
              role={UserBusinessRoles.BUYING}
            >
              <NavBar />
              <EnhancedScreenLockInactivity>
                <Switch>
                  <Route
                    path="/supplierAccounts"
                    component={EnhancedSupplierAccount}
                  />
                  <Route path="/search" component={PharmacyHome} />
                  <Route path="/selectDrug" component={SelectDrug} />
                  <Route
                    path="/refill-opportunities"
                    component={RefillOpportunitiesPage}
                  />
                  <Route path="/compare" component={Compare} />
                </Switch>
              </EnhancedScreenLockInactivity>
            </RoleProtectedRoute>

            <Route path="/(supplierNavigation|previousShoppingLists|shopping-list-add-rx|shoppingList|shoppingListTwo|ShoppingListThree|shoppingListPrint|testShoppingListEnhance)">
              <StorageContextProvider>
                <ShoppingNotificationsContextProvider>
                  <ShoppingCartServerContextProvider>
                    <ShoppingContextProvider>
                      <Route
                        exact
                        path="/supplierNavigation"
                        component={EnhancedSupplierNavBar}
                      />

                      <RoleProtectedRoute
                        path="/(previousShoppingLists|shopping-list-add-rx|shoppingList|shoppingListTwo|ShoppingListThree|shoppingListPrint|testShoppingListEnhance)"
                        role={UserBusinessRoles.BUYING}
                        component={Shopping}
                      />
                    </ShoppingContextProvider>
                  </ShoppingCartServerContextProvider>
                </ShoppingNotificationsContextProvider>
              </StorageContextProvider>
            </Route>

            <RoleProtectedRoute
              path="/performance"
              role={UserBusinessRoles.REPORTING}
              component={Performance}
            />

            <RoleProtectedRoute
              path="/manufacturer"
              role={UserBusinessRoles.MANUFACTURING}
              component={Manufacturer}
            />

            <RoleProtectedRoute
              path="/download"
              component={DownloadClientApp}
              role={UserBusinessRoles.BUYING}
            />

            <Route path="/unauthorized" component={UnauthorizedPage} />

            <Route path="/admin" component={Admin} />
          </Switch>
        </ClientPageAlertProvider>

        <PharmacyLoader />
        <ChatButton />
        <ScrollToTop />
        <IdentifyUsers />
      </Router>
    </div>
  );
}

/**
 * Note: This component should be placed at the bottom of the app component to ensure that the title page is updated
 */
function IdentifyUsers() {
  const { user } = useContext(AppContext);
  const { enhancedClientVersion } = useContext(EnhancedClientConfigContext);
  const { currentBuyingPharmacy } = useBuyingPharmacy();
  const location = useLocation();

  const userNameWithPharmacy = useMemo(() => {
    if (!user) return;
    if (!currentBuyingPharmacy) return user.full_name;
    return `${user.full_name} - ${currentBuyingPharmacy.name}`;
  }, [user, currentBuyingPharmacy]);

  // Sentry
  useEffect(() => {
    if (!user) return;

    const { id, email, is_admin: isAdmin } = user;
    Sentry.setUser({
      id: id.toString(),
      email,
      username: userNameWithPharmacy,
    });
    posthog.identify("email", {
      id: id.toString(),
      email,
      isAdmin,
      username: userNameWithPharmacy,
      clientSessionId: X_CLIENT_SESSION_ID,
    });

    if (isAdmin) Sentry.setTag("user.isAdmin", 1);
  }, [user, userNameWithPharmacy]);

  // Chatlio
  useEffect(() => {
    // The global chatlio variable is an empty array until the chatlio script is loaded
    if (
      Array.isArray(window._chatlio) ||
      !(window._chatlio.vsUuid && window._chatlio.vsUuid())
    ) {
      document.addEventListener("chatlio.ready", identifyChatlioUser);

      return () => {
        document.removeEventListener("chatlio.ready", identifyChatlioUser);
      };
    } else {
      identifyChatlioUser();
      return () => {};
    }

    function identifyChatlioUser() {
      window._chatlio.hide();
      if (user) {
        window._chatlio.identify(user.id.toString(), {
          name: user.full_name,
          email: user.email,
          title: document.title,
          version: enhancedClientVersion || "Web",
          "Pharmacy ID": currentBuyingPharmacy?.id,
          "Pharmacy Name": currentBuyingPharmacy?.name,
        });
      } else {
        window._chatlio.reset();
      }
    }
  }, [user, location.pathname, currentBuyingPharmacy, enhancedClientVersion]);

  return null;
}

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}
