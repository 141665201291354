import dayjs from "dayjs";
import { ColDef, ColGroupDef, ValueGetterParams } from "ag-grid-community";
import { currencyFormatter, numberFormatter } from "../../utils/performanceReportFormatters";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    field: "drugName",
    headerName: "Drug Name",
    pinned: "left",
    flex: 1,
    minWidth: 220,
  },
  {
    field: "pharmacyId",
    headerName: "Pharmacy ID",
    hide: true,
    flex: 1,
    minWidth: 120,
  },
  {
    field: "pharmacyName",
    headerName: "Pharmacy Name",
    hide: true,
    flex: 1,
    minWidth: 150,
  },
  {
    field: "purchasedQuantity",
    headerName: "Purchased Quantity",
    valueFormatter: (params) => numberFormatter(params.value),
    hide: true,
    flex: 1,
    minWidth: 120,
  },
  {
    field: "purchasedNdc",
    headerName: "Purchased NDC",
    flex: 1,
    minWidth: 150,
    hide: true,
  },
  {
    field: "purchasedSupplier",
    headerName: "Purchased Supplier",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "purchasedPackSize",
    headerName: "Purchased Pack Size",
    valueFormatter: (params) => numberFormatter(params.value),
    hide: true,
    flex: 1,
    minWidth: 120,
  },
  {
    field: "purchasedNetUnitPrice",
    headerName: "Purchased Net Unit Price",
    valueFormatter: (params) => currencyFormatter(params.value),
    hide: true,
    flex: 1,
    minWidth: 120,
  },
  {
    field: "purchasedTotalPrice",
    headerName: "Purchased Total Price",
    valueFormatter: (params) => currencyFormatter(params.value),
    hide: true,
    flex: 1,
    minWidth: 120,
  },
  {
    field: "suggestedQuantity",
    headerName: "Suggested Quantity",
    valueFormatter: (params) => numberFormatter(params.value),
    hide: true,
    flex: 1,
    minWidth: 120,
  },
  {
    field: "suggestedSupplier",
    headerName: "Suggested Supplier",
    flex: 1,
    minWidth: 180,
    valueFormatter: (params) => {
      const suggestedSupplier = params.value;
      const purchasedSupplier = params.data.purchasedSupplier;
      if (suggestedSupplier === purchasedSupplier) {
        return "No Change";
      }
      return suggestedSupplier;
    },
  },
  {
    field: "suggestedNdc",
    headerName: "Suggested NDC",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "suggestedPackSize",
    headerName: "Suggested Pack Size",
    valueFormatter: (params) => numberFormatter(params.value),
    hide: true,
    flex: 1,
    minWidth: 120,
  },
  {
    field: "suggestedUnitPrice",
    headerName: "Suggested Unit Price",
    valueFormatter: (params) => currencyFormatter(params.value),
    hide: true,
    flex: 1,
    minWidth: 120,
  },
  {
    field: "suggestedTotalPrice",
    headerName: "Suggested Total Price",
    valueFormatter: (params) => currencyFormatter(params.value),
    hide: true,
    flex: 1,
    minWidth: 120,
  },
  {
    field: "ndcSubstSuggested",
    headerName: "NDC Substitution Suggested",
    hide: true,
    flex: 1,
    minWidth: 120,
  },
  {
    field: "supplierChangeSuggested",
    headerName: "Supplier Change Suggested",
    hide: true,
    flex: 1,
    minWidth: 120,
  },
  {
    field: "packSizeChangeSuggested",
    headerName: "Pack Size Change Suggested",
    hide: true,
    flex: 1,
    minWidth: 120,
  },
  {
    field: "suggestedChanges",
    headerName: "Suggested Changes",
    hide: true,
    flex: 1,
    minWidth: 200,
  },
  {
    field: "totalSavings",
    headerName: "Estimated Total Savings",
    valueFormatter: (params) => currencyFormatter(params.value),
    flex: 1,
    minWidth: 150,
    sort: "desc",
  },
  {
    field: "perUnitSavings",
    headerName: "Per Unit Estimated Total Savings",
    valueFormatter: (params) => currencyFormatter(params.value),
    flex: 1,
    minWidth: 150,
  },
  {
    field: "daylightrxReference",
    headerName: "DaylightRx Reference",
    hide: true,
    flex: 1,
    minWidth: 300,
  },
  {
    field: "invoiceDate",
    headerName: "Invoice Date",
    flex: 1,
    width: 130,
    filter: "agSetColumnFilter",
    cellDataType: "string",
    filterParams: {
      valueGetter: (params: ValueGetterParams) => {
        return params.data["invoiceDate"].split(",");
      },
      comparator: (a: string, b: string) => {
        const valA = dayjs(a, "YYYY-MM-DD");
        const valB = dayjs(b, "YYYY-MM-DD");
        if (valA.isSame(valB)) return 0;
        return valA.isAfter(valB) ? 1 : -1;
      },
    },
  },
];
