import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import MailOutlined from "@ant-design/icons/MailOutlined";
import { Checkbox, Form, Input, Popconfirm, Select, Tooltip } from "antd";
import { UpsertAdminPharmacy } from "../../../../../../utilities/types";
import { Button } from "../../../../../../components/rxLibrary/buttons";
import { FormSkeleton } from "../../../../../../components/antd/form/FormSkeleton";
import {
  OnSubmit,
  useHandleSubmit,
} from "../../../../../../components/antd/form/useHandleSubmit";
import { PmsEnum } from "../../../../../../utilities/pms";
import { ViewChangeLogNotes } from "../../components/ViewChangeLogNotes";
import { AdminManagementHeader } from "../../components/AdminManagementHeader";
import { useAdminPharmacyOptions } from "./useAdminPharmacyOptions";

export function AdminPharmacyForm({
  id,
  isUpdateForm,
  initialValues,
  changeLogNotes,
  onSubmit,
}: {
  id?: number;
  isUpdateForm?: boolean;
  initialValues?: Partial<UpsertAdminPharmacy>;
  changeLogNotes?: string[];
  onSubmit: OnSubmit<UpsertAdminPharmacy>;
}) {
  const { form, isSubmitting, handleSubmit } = useHandleSubmit({
    onSubmit,
  });
  const adminPharmacyOptions = useAdminPharmacyOptions();
  const {
    pmsOptions,
    stateOptions,
    supplierOptions,
    timezoneOptions,
    supplierScopeOptions,
    pharmacyOrganizationOptions,
    existingPharmacies,
  } = adminPharmacyOptions ?? {};
  const pms = Form.useWatch("pms", form);
  const isPmsLiberty = pms === PmsEnum.Liberty;

  const title = isUpdateForm
    ? `Edit Pharmacy ${initialValues?.name}`
    : `Create Pharmacy`;
  const submitBtn = isUpdateForm ? "Update Pharmacy" : "Create Pharmacy";

  const loading = isUpdateForm ? !initialValues : false;

  const onValuesChange = useCallback(
    (changedValues: any) => {
      if ("pms" in changedValues && changedValues.pms !== PmsEnum.Liberty) {
        form.setFieldValue("syncLibertyInventory", false);
      }
    },
    [form]
  );

  const nameLeadingAndTrailingWhitespaceValidator = useCallback(
    async (_, name: string) => {
      if (isUpdateForm) return;

      if (name.trim() !== name) {
        throw new Error('Do not include a leading or trailing space character');
      }
    },
    [isUpdateForm]
  )

  const initialName = initialValues?.name;
  const changeNameValidator = useCallback(
    async (_, name: string) => {
      if (!isUpdateForm) return;

      if (name !== initialName) {
        throw new Error('Warning: potential back-end issues if you change the pharmacy name. Make sure to check with back-end engineering team before changing');
      }
    },
    [isUpdateForm, initialName]
  )

  const pharmacyAlreadyAddedValidator = useCallback(
    async (_, name: string) => {
      if (isUpdateForm && initialName === name) return Promise.resolve();

      const pharmacyAlreadyAdded = existingPharmacies?.find((v) => v.name === name);
      if (pharmacyAlreadyAdded) {
        const linkToEdit = <a tw="font-bold" target="_blank" rel="noreferrer noopener" href={`/admin/pharmacy/${pharmacyAlreadyAdded.id}`}>click here</a>;
        return Promise.reject(<>A pharmacy already exists with this name. To edit this pharmacy {linkToEdit}</>)
      }
    },
    [existingPharmacies, isUpdateForm, initialName]
  );

  if (loading || !adminPharmacyOptions) {
    return <FormSkeleton text="Loading Pharmacy" />;
  }

  return (
    <>
      <AdminManagementHeader
        breadcrumbs={[
          { title: <Link to="/admin/pharmacy">Pharmacies</Link> },
          { title },
        ]}
      >
        {!!id && (
          <>
            <Tooltip title="Open supplier mappings filtered by pharmacy">
              <Link to={`/admin/supplier-mapping?pharmacy=${id}`}>
                <Button>Supplier Mappings</Button>
              </Link>
            </Tooltip>

            <Tooltip title="Open invoice email filtered by pharmacy">
              <Link
                tw="underline"
                to={`/admin/pharmacy/email/subscription?pharmacy=${id}`}
              >
                <Button icon={<MailOutlined rev={undefined} />}>
                  Pharmacy Email Subscriptions
                </Button>
              </Link>
            </Tooltip>
          </>
        )}

        <ViewChangeLogNotes changeLogNotes={changeLogNotes} />
      </AdminManagementHeader>

      <Form
        scrollToFirstError
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        disabled={isSubmitting}
        onFinish={handleSubmit}
        onValuesChange={onValuesChange}
      >
        <div tw="grid grid-cols-2 gap-x-8">
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true },
              { whitespace: true },
              { validator: nameLeadingAndTrailingWhitespaceValidator },
              { validator: changeNameValidator, warningOnly: true },
              { validator: pharmacyAlreadyAddedValidator },
            ]}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item label="Legal Pharmacy Name" name="legalPharmacyName">
            <Input allowClear />
          </Form.Item>

          <Form.Item
            label="Pharmacy Organization"
            name="pharmacyOrganizationId"
            rules={[{ required: true }]}
          >
            <Select
              options={pharmacyOrganizationOptions}
              optionFilterProp="label"
              showSearch
              allowClear
            />
          </Form.Item>

          <Form.Item label="PMS" name="pms" rules={[{ required: true }]}>
            <Select
              options={pmsOptions}
              optionFilterProp="label"
              showSearch
              allowClear
            />
          </Form.Item>

          <Form.Item
            label="DEA Code"
            name="deaCode"
            rules={[
              {
                pattern: /^[A-Z0-9]{9}$/,
                message:
                  "${label} must be 9 characters, capital letters and numbers only",
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item label="NPI" name="npi" rules={[{ len: 10 }]}>
            <Input type="number" allowClear />
          </Form.Item>

          <Form.Item
            label="NABP Number"
            name="nabpNumber"
            rules={[
              {
                pattern: /^\d{7}$/,
                message: "${label} must be 7 digits long",
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item label="Board of Pharmacy License" name="license">
            <Input allowClear />
          </Form.Item>

          <Form.Item
            label="Timezone"
            name="timezone"
            rules={[{ required: true }]}
          >
            <Select
              options={timezoneOptions}
              optionFilterProp="label"
              showSearch
            />
          </Form.Item>

          <Form.Item label="Address" name="address">
            <Input allowClear />
          </Form.Item>

          <Form.Item label="City" name="city">
            <Input allowClear />
          </Form.Item>

          <Form.Item label="State" name="state">
            <Select
              options={stateOptions}
              optionFilterProp="label"
              showSearch
              allowClear
            />
          </Form.Item>

          <Form.Item label="Postal Code" name="postalCode">
            <Input type="number" minLength={4} maxLength={9} allowClear />
          </Form.Item>

          <Form.Item
            label="Supplier Scope"
            name="supplierScope"
            rules={[{ required: true }]}
          >
            <Select
              options={supplierScopeOptions}
              optionFilterProp="label"
              showSearch
            />
          </Form.Item>

          <Form.Item
            label="Primary Supplier (Optional)"
            name="primarySupplierId"
          >
            <Select
              options={supplierOptions}
              optionFilterProp="label"
              showSearch
              allowClear
            />
          </Form.Item>

          <Form.Item
            label="Primary Secondary Supplier (Optional)"
            name="secondarySupplierId"
          >
            <Select
              options={supplierOptions}
              optionFilterProp="label"
              showSearch
              allowClear
            />
          </Form.Item>

          <Form.Item
            name="isSynced"
            label="Show PMS info on P1"
            valuePropName="checked"
            help="All pharmacies, including ecommerce, should be set to true. This allows full app pharmacies to use AddRx & have their PMS items on the old p1."
            rules={[{ required: true }]}
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            name="usePmsSchema"
            valuePropName="checked"
            label="Use PMS Schema"
            help="Use multi-PMS merged database schema on back-end. True for all PMSs, even if not compatible."
            rules={[{ required: true }]}
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            name="syncLibertyInventory"
            valuePropName="checked"
            label="Sync Liberty Inventory"
            tooltip="Set this to True if the pharmacy has accurate inventory and we want to fetch this every hour from the Liberty servers"
          >
            <Checkbox disabled={!isPmsLiberty} />
          </Form.Item>

          <Form.Item label="Change Log Note" name="changeLogNote">
            <Input allowClear />
          </Form.Item>
        </div>

        <Popconfirm title="Sure to continue?" onConfirm={form.submit}>
          <Button disabled={isSubmitting}>{submitBtn}</Button>
        </Popconfirm>
      </Form>
    </>
  );
}
