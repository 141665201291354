import type {
  AdminPharmacyFull,
  AdminPharmacyOptions,
  DrugInfo,
  Pharmacy,
  PharmacyRefillItem,
  UpsertAdminPharmacy,
} from "../utilities/types";
import type { RequestClientConfig } from "./request/requestClient";
import {
  BackendResponse,
  backendFetch,
  backendPost,
} from "./request_legacy/config/backend-api";
import { requestClient } from "./request/requestClient";

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getPharmacies(token: string) {
  return backendFetch<{ pharmacies: Pharmacy[] }>("/pharmacies", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

/**
 * @deprecated please use getAdminPharmacy
 **/
export async function getAdminPharmacy_legacy(
  token: string,
  pharmacyId: string
) {
  const response = await backendFetch<{ pharmacy: AdminPharmacyFull }>(
    `/admin/pharmacy/${pharmacyId}`,
    {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    }
  );

  if (response.error) {
    console.error("Error fetching admin pharmacy", response);
  }

  return response;
}

export async function getAdminPharmacy({
  id,
  ...config
}: RequestClientConfig<{
  id: number | string;
}>) {
  return requestClient<{ pharmacy: AdminPharmacyFull }>({
    ...config,
    url: `/admin/pharmacy/${id}`,
  });
}

/**
 * @deprecated please migrate/use to getAdminPharmacies
 **/
export async function getAdminPharmacies_legacy(token: string) {
  const response = await backendFetch<{ pharmacies: AdminPharmacyFull[] }>(
    "/admin/pharmacy",
    {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    }
  );

  if (response.error) {
    console.error("Error fetching admin pharmacies", response);
  }

  return response;
}

export async function getAdminPharmacies(
  config?: RequestClientConfig<Record<string, never>>
) {
  return requestClient<{ pharmacies: AdminPharmacyFull[] }>({
    ...config,
    url: "/admin/pharmacy",
  });
}

export async function getAdminPharmacyOptions(
  config?: RequestClientConfig<Record<string, never>>
) {
  return requestClient<{ pharmacyOptions: AdminPharmacyOptions }>({
    ...config,
    url: "/admin/pharmacy/options",
  });
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function createAdminPharmacy(
  token: string,
  data: UpsertAdminPharmacy
) {
  const payload = JSON.stringify({ data });
  const responseRaw = await backendPost("/admin/pharmacy", payload, {
    headers: { Authorization: "Bearer " + token },
  });
  const response = (await responseRaw.json()) as BackendResponse<{
    pharmacy: AdminPharmacyFull;
  }>;

  if (response.error) {
    console.error(
      "Error creating admin pharmacy",
      responseRaw,
      response,
      payload
    );
  }

  return response;
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function updateAdminPharmacy(
  token: string,
  pharmacyId: string,
  data: UpsertAdminPharmacy
) {
  const payload = JSON.stringify({ data });
  const responseRaw = await backendPost(
    `/admin/pharmacy/${pharmacyId}`,
    payload,
    {
      method: "PUT",
      headers: { Authorization: "Bearer " + token },
    }
  );
  const response = (await responseRaw.json()) as BackendResponse<{
    pharmacy: AdminPharmacyFull;
  }>;

  if (response.error) {
    console.error(
      "Error updating admin pharmacy",
      responseRaw,
      response,
      payload
    );
  }

  return response;
}

export async function getPharmacyRefillItemsDrugs({
  supplierId,
  ...config
}: RequestClientConfig<{
  supplierId: number;
}>) {
  return requestClient<{ drugs: DrugInfo[] }>({
    ...config,
    url: `/pharmacy/refill-item/drug/${supplierId}`,
  });
}

export async function getPharmacyRefillItems({
  pharmacyId,
  ...config
}: RequestClientConfig<{ pharmacyId: number }>) {
  return requestClient<{
    refillItemsBySupplierId: Record<string, PharmacyRefillItem[]>;
  }>({
    ...config,
    url: `/pharmacy/refill-item/${pharmacyId}`,
  });
}

export async function createPharmacyRefillItem({
  ndc,
  cost,
  supplierId,
  pharmacyId,
  ...config
}: RequestClientConfig<{
  ndc: string;
  cost: number;
  pharmacyId: number;
  supplierId: number;
}>) {
  return requestClient({
    ...config,
    url: `/pharmacy/refill-item/${pharmacyId}`,
    method: "POST",
    data: { ndc, cost, supplierId },
  });
}

export async function updatePharmacyRefillItem({
  ndc,
  cost,
  supplierId,
  pharmacyId,
  ...config
}: RequestClientConfig<{
  ndc: string;
  cost: number;
  pharmacyId: number;
  supplierId: number;
}>) {
  return requestClient({
    ...config,
    url: `/pharmacy/refill-item/${pharmacyId}`,
    method: "PUT",
    data: { ndc, cost, supplierId },
  });
}
