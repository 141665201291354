import React, { useMemo } from "react";
import "twin.macro";
import { Link } from "react-router-dom";
import { Form, Input, Popconfirm, Select } from "antd";
import { UpsertAdminSupplierMappingAndEdi } from "../../../../../../utilities/types";
import { Button } from "../../../../../../components/rxLibrary/buttons";
import {
  OnSubmit,
  useHandleSubmit,
} from "../../../../../../components/antd/form/useHandleSubmit";
import { FormSkeleton } from "../../../../../../components/antd/form/FormSkeleton";
import { AdminManagementHeader } from "../../components/AdminManagementHeader";
import { Checkbox } from "../../../../../../components/rxLibrary/checkbox";
import { Text } from "../../../../../../components/rxLibrary/typography";
import { CustomerAccountInput } from "../../components/AccountIdInput/CustomerAccountInput";
import { noLowercaseLettersValidator } from "../../../../../../components/antd/form/validators/noLowercaseLettersValidator";
import { useAdminSupplierMappingAndEdiFormOptions } from "./useAdminSupplierMappingAndEdiFormOptions";
import { AdminSupplierMappingAndEdiFormPharmacyIsaCodeLabel } from "./labels/AdminSupplierMappingAndEdiFormPharmacyIsaCodeLabel";
import {
  SUPPLIER_ID_TOP_RX,
  SUPPLIER_ID_SMITH_DRUG,
  SUPPLIER_ID_INTEGRAL_RX,
} from "../../../../../../utilities/config";

export function AdminSupplierMappingAndEdiForm({
  isUpdateForm,
  initialValues,
  onSubmit,
}: {
  isUpdateForm?: boolean;
  initialValues?: Partial<UpsertAdminSupplierMappingAndEdi>;
  onSubmit: OnSubmit<UpsertAdminSupplierMappingAndEdi>;
}) {
  const { form, isSubmitting, handleSubmit } = useHandleSubmit({
    onSubmit,
  });
  const pharmacyId = Form.useWatch("pharmacyId", form);
  const supplierId = Form.useWatch("supplierId", form);
  const {
    pharmacy,
    pharmacyOptions,
    supplierOptions,
    billToPharmacyIdOptions,
    selectedSupplier,
  } = useAdminSupplierMappingAndEdiFormOptions({ supplierId, pharmacyId });

  const title = isUpdateForm
    ? `Edit Supplier Mapping & EDI`
    : "Create Supplier Mapping & EDI";
  const submitBtn = isUpdateForm
    ? "Update Supplier Mapping & EDI"
    : "Create Supplier Mapping & EDI";
  const parentPath = supplierId
    ? `/admin/supplier-mapping-and-edi/${supplierId}`
    : "/admin/supplier-mapping-and-edi";

  const supplierName = useMemo(() => {
    return supplierOptions?.find((v) => v.value === supplierId)?.label;
  }, [supplierId, supplierOptions]);

  const loading = isUpdateForm ? !initialValues || isSubmitting : false;
  if (loading) {
    return <FormSkeleton text="Loading Supplier Mapping & EDI" />;
  }

  return (
    <>
      <AdminManagementHeader
        breadcrumbs={[
          {
            title: <Link to={parentPath}>Supplier Mapping & EDI</Link>,
          },
          { title },
        ]}
      />

      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSubmit}
        disabled={isSubmitting}
        scrollToFirstError
      >
        <div tw="grid grid-cols-2 gap-x-8">
          <Form.Item
            label="Supplier"
            name="supplierId"
            rules={[{ required: true }]}
            extra={
              supplierId === SUPPLIER_ID_TOP_RX ||
              supplierId === SUPPLIER_ID_SMITH_DRUG ||
              supplierId === SUPPLIER_ID_INTEGRAL_RX ? (
                <>
                  <Text color="orange-1" tw="mt-1" block>
                    Warning: unlike other suppliers, only add settings here if
                    you know that the EDI has ALREADY been added by the supplier
                    (or jobs will fail)
                  </Text>

                  {supplierId === SUPPLIER_ID_INTEGRAL_RX && (
                    <Text color="orange-1" tw="mt-1" block>
                      Warning: This supplier has multiple distribution centers,
                      please confirm which distribution center this pharmacy
                      uses and let Engineering team know which catalog to use
                    </Text>
                  )}
                </>
              ) : null
            }
          >
            <Select
              options={supplierOptions}
              optionFilterProp="label"
              showSearch
            />
          </Form.Item>

          <Form.Item
            label="Pharmacy"
            name="pharmacyId"
            rules={[{ required: true }]}
          >
            <Select
              options={pharmacyOptions}
              optionFilterProp="label"
              showSearch
              allowClear
            />
          </Form.Item>

          <Form.Item
            label={
              <AdminSupplierMappingAndEdiFormPharmacyIsaCodeLabel
                supplierId={selectedSupplier?.value}
                supplierName={selectedSupplier?.label}
              />
            }
            name="pharmacyIsaCode"
            rules={[
              { required: true, message: "Please enter Pharmacy ISA Code" },
              {
                validator: noLowercaseLettersValidator,
                message:
                  "Warning: Pharmacy ISA Codes rarely have lowercase letters. Please confirm.",
                warningOnly: true,
              },
              {
                pattern: /^[a-zA-Z0-9]+$/g,
                message:
                  "Pharmacy ISA Codes should only be letters and numbers",
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>

          <CustomerAccountInput
            name="accountId"
            supplierId={supplierId}
            supplierName={supplierName}
            rules={[
              { required: true, message: "Please enter Customer Account ID" },
            ]}
          />

          <Form.Item
            label="Is Supplier Mapping Active"
            name="isSupplierMappingActive"
            valuePropName="checked"
            rules={[{ required: true }]}
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            label="Bill To Pharmacy Id"
            name="billToPharmacyId"
            rules={[{ required: true }]}
          >
            <Select
              options={billToPharmacyIdOptions}
              optionFilterProp="label"
              showSearch
              allowClear
              disabled={!billToPharmacyIdOptions}
            />
          </Form.Item>

          <Form.Item label="Ship To Information">
            <Text color="grey-1" block>
              Ship To information will use default info for{" "}
              {pharmacy?.label ?? "selected pharmacy"}
            </Text>
          </Form.Item>
        </div>

        <Popconfirm title="Sure to continue?" onConfirm={form.submit}>
          <Button disabled={isSubmitting}>{submitBtn}</Button>
        </Popconfirm>
      </Form>
    </>
  );
}
