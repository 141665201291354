import { Dayjs } from "dayjs";
import type { ApiPaginationRequest } from "../components/rxLibrary/pagination/pagination.utils";
import type {
  ManufacturerAgGridReport,
  ReportRefillOpportunityWithPagination,
} from "../utilities/types";
import { SortDirectionEnum } from "../components/rxLibrary/selects/SortBySelect";
import { requestClient, RequestClientConfig } from "./request/requestClient";

export function getReportRefillOpportunity({
  endDate,
  startDate,
  pharmacyId,
  sortColumn,
  sortDirection,
  pageSize,
  pageNumber,
  manufacturerIds,
  showOnlyCoveredFills,
  showOnlyPaidOutreaches,
  ...config
}: RequestClientConfig<
  ApiPaginationRequest & {
    pharmacyId: number;
    startDate: Dayjs;
    endDate: Dayjs;
    sortColumn?: string;
    sortDirection?: SortDirectionEnum;
    manufacturerIds?: number[];
    showOnlyCoveredFills?: boolean;
    showOnlyPaidOutreaches?: boolean;
  }
>) {
  const params: Record<string, any> = {
    version: 2,
    pharmacyId,
    endDate: endDate.format("YYYY-MM-DD"),
    startDate: startDate.format("YYYY-MM-DD"),
    sortColumn,
    pageNumber,
    sortDirection,
    // if it is 0, the BE will skip the pagination
    pageSize,
    manufacturerId: manufacturerIds,
  };

  if (showOnlyCoveredFills !== undefined) {
    params.showOnlyCoveredFills = showOnlyCoveredFills ? "1" : "0";
  }
  if (showOnlyPaidOutreaches !== undefined) {
    params.showOnlyPaidOutreaches = showOnlyPaidOutreaches ? "1" : "0";
  }

  return requestClient<{
    refillOpportunities: ReportRefillOpportunityWithPagination;
  }>({
    ...config,
    url: "/report/refill-opportunity",
    params,
  }).then((response) => {
    // Trick to make the response compatible with the expected format of the RequestClient
    const data = response?.data?.data;
    if (!data) return response;

    const newResponse = {
      ...response,
      data: { data: { refillOpportunities: data } },
    } as any as typeof response;

    return newResponse;
  });
}

export async function getManufacturerAgGridReport({
  reportId,
  manufacturerId,
  startDate,
  endDate,
  ...config
}: RequestClientConfig<{
  reportId: number;
  startDate: string;
  endDate: string;
  manufacturerId: number;
}>) {
  return requestClient<{
    manufacturerAgGridReport: ManufacturerAgGridReport;
  }>({
    ...config,
    url: `/report/${reportId}/manufacturer/${manufacturerId}/ag-grid`,
    params: {
      fromDate: startDate,
      toDate: endDate,
      checkPermissions: 1,
    },
  });
}

export async function getModeUrlReport({
  fromDate, // start date
  toDate, // end date
  reportId,
  pharmacyId,
  manufacturerId,
  ...config
}: RequestClientConfig<{
  reportId: number;
  fromDate: string;
  toDate: string;
  pharmacyId?: number | number[];
  manufacturerId?: number;
}>) {
  return requestClient<{ signedUrl: string }>({
    ...config,
    url: "/report/mode-url",
    params: { reportId, fromDate, toDate, manufacturerId, pharmacyId },
  });
}
