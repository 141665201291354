import React from "react";
import { Link } from "react-router-dom";
import { Checkbox, Form, Input, Popconfirm, Select } from "antd";
import type {
  AdminPageAlert,
  AdminPageAlertOptions,
  UpsertAdminPageAlert,
} from "../../../../../../utilities/types";
import { PAGE_ALERTS_BLACKLISTED_PATHS } from "../../../../../../utilities/config";
import { getAdminPageAlertsOptions } from "../../../../../../services/pageAlerts";
import { useRequest } from "../../../../../../services/request/useRequest";
import { Button } from "../../../../../../components/rxLibrary/buttons";
import {
  OnSubmit,
  useHandleSubmit,
} from "../../../../../../components/antd/form/useHandleSubmit";
import { FormSkeleton } from "../../../../../../components/antd/form/FormSkeleton";
import { AdminManagementHeader } from "../../components/AdminManagementHeader";
import { semverValidator } from "../../../../../../components/antd/form/validators/semverValidator";
import { urlPathValidator } from "../../../../../../components/antd/form/validators/urlPathValidator";
import { SimpleLink } from "../../../../../../components/rxLibrary/Link";
import { PharmaciesLabelWithAddByIdPopover } from "./PharmaciesLabelWithAddByIdPopover";
import { PageAlertsPreviewButton } from "./PageAlertsPreviewButton";

function optionsFormatter(options: AdminPageAlertOptions) {
  const { pharmacy, supplier } = options;
  const pharmacyOptions = pharmacy.map((pharmacy) => ({
    value: pharmacy.id,
    label: `${pharmacy.name} (${pharmacy.id})`,
  }));
  const supplierManufacturerStoreLinkOptions = supplier.map((supplier) => ({
    value: `/manufacturer-store/${supplier.id}`,
    label: `Manufacturer Store (${supplier.id}): ${supplier.name}`,
  }));
  return { pharmacyOptions, supplierManufacturerStoreLinkOptions };
}

export function AdminPageAlertForm({
  isUpdateForm,
  initialValues,
  onSubmit,
}: {
  isUpdateForm?: boolean;
  initialValues?: Partial<AdminPageAlert>;
  onSubmit: OnSubmit<UpsertAdminPageAlert>;
}) {
  const { form, isSubmitting, handleSubmit } = useHandleSubmit({
    onSubmit,
  });
  const text = Form.useWatch("message", form);

  const { formattedData } = useRequest({
    dataKey: "pageAlertOptions",
    request: getAdminPageAlertsOptions,
    formatter: optionsFormatter,
  });

  const { pharmacyOptions, supplierManufacturerStoreLinkOptions } =
    formattedData ?? {};

  const title = isUpdateForm
    ? `Edit Page Alert ${initialValues?.id}`
    : "Create Page Alert";
  const submitBtn = isUpdateForm ? "Update Page Alert" : "Create Page Alert";

  const loading = isUpdateForm ? !initialValues || isSubmitting : false;
  if (loading) {
    return <FormSkeleton text="Loading Page Alert" />;
  }

  return (
    <>
      <AdminManagementHeader
        breadcrumbs={[
          {
            title: <Link to="/admin/page-alert">Page Alert</Link>,
          },
          { title },
        ]}
      >
        <div>
          <PageAlertsPreviewButton text={text} />
        </div>
      </AdminManagementHeader>

      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSubmit}
        disabled={isSubmitting}
        scrollToFirstError
      >
        <div tw="grid grid-cols-2 gap-x-8">
          <Form.Item
            label="Message"
            name="message"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="enabled" label="Enabled" valuePropName="checked">
            <Checkbox />
          </Form.Item>

          <Form.Item
            name="electronOnly"
            label="Electron Only"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            label="Electron Max Version"
            name="electronMaxVersion"
            rules={[{ validator: semverValidator }]}
            tooltip="Maximum version at which the page alert will be displayed"
            extra={
              <span>
                Use{" "}
                <SimpleLink
                  tw="underline"
                  href="https://semver.org/"
                  openInNewTab
                  noStyle
                >
                  semver
                </SimpleLink>{" "}
                format, Example: 1.0.0
              </span>
            }
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            name="pharmacyIds"
            label={
              <PharmaciesLabelWithAddByIdPopover
                pharmacyOptions={pharmacyOptions}
              />
            }
            extra="If at least one pharmacy is selected, then this page alert will only be shown on these pharmacies"
          >
            <Select
              mode="multiple"
              options={pharmacyOptions}
              optionFilterProp="label"
              showSearch
              allowClear
            />
          </Form.Item>

          <Form.Item
            label="Pages"
            name="pages"
            rules={[{ validator: urlPathValidator }]}
            extra={
              <>
                <p>You can type any path that is not on the list.</p>
                <p>
                  If at least one page is selected, this page alert is going to
                  be shown only on these pages
                </p>
              </>
            }
          >
            <Select
              mode="tags"
              tokenSeparators={[","]}
              placeholder="Example: /shoppingList"
              options={supplierManufacturerStoreLinkOptions}
              allowClear
            />
          </Form.Item>

          <Form.Item
            label="Blacklisted Pages"
            name="blacklistedPages"
            rules={[{ validator: urlPathValidator }]}
            extra={`These paths will always be blacklisted: ${PAGE_ALERTS_BLACKLISTED_PATHS.join(
              ", "
            )}`}
          >
            <Select
              mode="tags"
              tokenSeparators={[","]}
              placeholder="Example: /shoppingList"
              allowClear
            />
          </Form.Item>
        </div>

        <Popconfirm title="Sure to continue?" onConfirm={form.submit}>
          <Button disabled={isSubmitting}>{submitBtn}</Button>
        </Popconfirm>
      </Form>
    </>
  );
}
